import { MaybeUndef, CustomHeader } from "shared";
import { Variables } from "react-relay";

export async function fetchGraphql(
  name: string,
  text: MaybeUndef<string>,
  variables: Variables
): Promise<Response> {
  return fetch(process.env.NEXT_PUBLIC_GRAPHQL_URL as string, {
    body: JSON.stringify({
      operationName: name,
      query: text,
      variables,
    }),
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      [CustomHeader.OperationName]: name,
    },
    method: "POST",
  });
}
