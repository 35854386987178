/* eslint-disable import/prefer-default-export */
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { Auth, getAuth } from "firebase/auth";
import { Analytics, getAnalytics } from "firebase/analytics";
import { FirebaseStorage, getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// REPLACE: replace with project-specific Firebase config
const firebaseConfig = {
  apiKey: "AIzaSyD_4sEP1RxZmiGJaB-Yy2J3qoQmuLh7gHk",
  appId: "1:1080046077031:web:2ab3c57e120e20da5ec0c2",
  authDomain: "side-project-stack-6ec08.firebaseapp.com",
  measurementId: "G-QJ2SY4CY69",
  messagingSenderId: "1080046077031",
  projectId: "side-project-stack-6ec08",
  storageBucket: "side-project-stack-6ec08.appspot.com",
};

// See this link for available libraries
// https://firebase.google.com/docs/web/setup#available-libraries

// Initialize Firebase
const app = initializeApp(firebaseConfig);

let firebaseAuth: Auth;
export function getFirebaseAuth() {
  if (firebaseAuth != null) {
    return firebaseAuth;
  }
  firebaseAuth = getAuth(app);
  return firebaseAuth;
}

let firebaseAnalytics: Analytics;
export function getFirebaseAnalytics() {
  if (firebaseAnalytics != null) {
    return firebaseAnalytics;
  }
  firebaseAnalytics = getAnalytics(app);
  return firebaseAnalytics;
}

// Initialize Firebase Storage
let firebaseStorage: FirebaseStorage;
export function getFirebaseStorage() {
  if (firebaseStorage != null) {
    return firebaseStorage;
  }
  firebaseStorage = getStorage(app);
  return firebaseStorage;
}
