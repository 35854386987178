import { getEnvironment } from "@web/utils/env/getEnvironment";
import { assertUnreachable, Environment } from "shared";

export function getTracesSampleRate() {
  const env = getEnvironment();
  switch (env) {
    case Environment.Local:
      return 0;
    case Environment.Development:
      return 0.01;
    case Environment.Production:
      return 0.05;
    default:
      return assertUnreachable(env);
  }
}
