import "css/Fonts.css";
import "@ui/css/Antd.css";
import "@ui/css/Global.css";
import "@ui/css/colors/BackgroundColorClasses.css";
import "@ui/css/colors/ColorClasses.css";
import "@ui/css/colors/ColorVariables.css";
import "@ui/css/colors/ColorVariablesDarkMode.css";
import "@ui/css/colors/ColorVariablesLightMode.css";
import "@ui/css/fonts/FontClasses.css";
import "@ui/css/fonts/FontSizes.css";
import "@ui/css/fonts/FontVariables.css";
import "@ui/css/shadows/ShadowVariables.css";
import "@ui/css/z-index/ZIndexVariables.css";
import { Roboto_Mono } from "next/font/google";
import { AppProps } from "next/app";
import { RelayEnvironmentProvider } from "react-relay";
import { RelayEnvironment } from "@web/utils/graphql/RelayEnvironment";
import { ViewerContextProvider } from "@web/contexts/ViewerContext";

const robotoMono = Roboto_Mono({
  subsets: ["latin"],
  weight: ["400", "500", "700"],
});

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      {/* For some reason, this works, but specifying `variable` in the font constructor does not */}
      {/* Keep variable name in sync with FontVariableName */}
      <style jsx global>{`
        :root {
          --roboto-mono: ${robotoMono.style.fontFamily};
        }
      `}</style>
      <RelayEnvironmentProvider environment={RelayEnvironment}>
        <ViewerContextProvider>
          <Component {...pageProps} />
        </ViewerContextProvider>
      </RelayEnvironmentProvider>
    </>
  );
}
